/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/* header */

.logo-icon {
  width: 32px;
  height: 32px;
}

div.header h1 {
  padding: 0;
}

div.header .logo {
  /* width: 50%;
  height: 50%; */
}

div.subheader {
  padding: 0px 15px;
}

/* hero */

.hero h1 {
  margin-bottom: 0px;
}
.hero h2 {
  margin-top: 0px;
  margin-bottom: 0px;
}
.hero h3 {
  margin-top: 0px;
  margin-bottom: 0px;
}
.hero p {
  margin-top: 0px;
  margin-bottom: 0px;
}

/* form */

p.intro {
  /* font-size: 0.9em; */
  -webkit-margin-before: 0em;
  -webkit-margin-after: 2em;
}
p.intro.less_space {
  /* font-size: 0.9em; */
  -webkit-margin-before: 1em;
  -webkit-margin-after: 1em;
}
.error {
  /* font-size: 0.9em; */
}

.hint {
  font-size: 0.8em;
  color: #888888;
}

.question {
  /* font-size: 0.9em; */
  color: #888888;
}

.section-complete {
  cursor: pointer;
}

.md-card-avatar-button {
  min-width: 0;
  min-height: 0;
  padding: 0;
  margin: 0;
}

.slider-invalid {
  color: rgb(221,44,0);
  font-size: 0.8em;
}

md-autocomplete {
  margin-top: -18px;
}

md-autocomplete md-input-container {
  /*margin-bottom: -18px;*/
}

md-input-container label.md-required,
md-select.ng-invalid-required md-select-value span,
p.p-required {
  color: #444444;
}

/* p.p-required:after {
  content: ' *';
  font-size: 13px;
  vertical-align: top;
  color: rgba(0,0,0,0.54);
} */

.form-progress {
  height: 100%;
  text-align: right;
  background-color: #00458F;
}

.form-progress .value {
  /* color: #00458F; */
}

.form-progress .percentage {
  /* color: #00458F; */
}

/* content */

a {
  color: #1E88E5;
}

a:hover {
  text-decoration: none;
}

/* list */

div.md-list-item-text p {
  -webkit-margin-before: 0.5em;
  -webkit-margin-after: 0.5em;
}

md-list-item.extra-space {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

md-list.md-dense-instructions {
  padding-bottom: 0px;
  margin-top: -1em;
  margin-bottom: 1em;
}

md-list.md-dense-instructions md-list-item {
  min-height: 30px;
  /* padding-left: 0px; */
}

md-list.md-dense-instructions md-list-item:before {
  min-height: 30px;
}

md-list.md-dense-instructions md-list-item div.md-list-item-text p {
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
}

/* footer */
div.footer p {
  padding: 0;
  font-size: 0.8em;
  color: #666;
}

div.footer a {
  color: #666;
}

div.footer a:hover {
  color: #000;
}
